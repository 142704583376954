.about {
    background-color: var(--white-color);
}

.about-title {
    text-align: center;
}

.about-title h1 {
    font-size: 4.342vw;
    font-weight: 700;
    line-height: 4.776vw    ;
}

.about-title p {
    color: #999;
    font-size: 1.389vw;
    line-height: 2.026vw    ;

    font-weight: 400;
    margin: 47px auto;
}

/* .about-imgs {
    display: flex;
    column-gap: 40px;
    width: 81.395348837209vw;
} */

/* .img1 {
    width: 51.187vw;
    height: 33.758vw
    ;
}

.img2 {
    width: 27.562vw;
    height: 33.758vw;
}

@media (max-width:428px) {
    .img1{
        width: 100%;
        height:500;
    };
    .img2{
        width: 100%;
        height:200;
    }
} */
.about-us {
    margin-top: 81px;
}

/* .about-us .about-left {
}

.about-us .about-right {
} */

.about-right p {
    color:rgba(153, 153, 153, 1);

}
